import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import ItemMobile from './ItemMobile';
import useStyles from './styles';

const PersonList = ({ team }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.desktopContainer}>
        {team.map(({ id, ...rest }) => (
          <Item key={id} {...rest} />
        ))}
      </div>
      <div className={classes.mobileContainer}>
        {team.map(({ id, ...rest }) => (
          <ItemMobile key={id} {...rest} />
        ))}
      </div>
    </>
  );
};

PersonList.propTypes = {
  team: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default PersonList;
