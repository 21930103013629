/* eslint-disable react/no-array-index-key */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import Arrow from './Arrow';
import useStyles from './styles';

const Item = ({ name, image, position, description, links }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {image ? (
          <GatsbyImage image={image} objectFit="cover" objectPosition="50% 50%" className={classes.image} alt="" />
        ) : (
          <div className={classes.image} />
        )}
      </div>
      <div className={classes.textContainer}>
        <div>
          <p className={classes.nameText}>{name}</p>
          <p className={classes.positionText}>{position}</p>
        </div>
        <div>
          {description.map((text, index) => (
            <p key={index} className={classes.text}>
              {text}
            </p>
          ))}
        </div>
      </div>
      <div className={classes.linkContainer}>
        {links.map(({ name: linkName, url }) => (
          <div key={url} className={classes.insideContainer}>
            <Arrow />
            <a href={url} className={classes.link} rel="noopener noreferrer">
              {linkName}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

Item.defaultProps = {
  links: [],
  description: [],
  image: null,
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  image: PropTypes.shape({}),
};

export default Item;
