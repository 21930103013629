import React from 'react';
import PropTypes from 'prop-types';

import Description from '@/components/Description';
import PersonList from '@/components/PersonList';

const Team = ({ pageContext }) => {
  const { title, description, items } = pageContext;

  return (
    <>
      <Description title={title} text={description} />
      <PersonList team={items} />
    </>
  );
};

Team.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default Team;
export { default as Head } from '@/components/Head';
