import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, components }) => ({
  desktopContainer: {
    margin: [spacing * 2.5, spacing * 2, 0, spacing * 2],
    paddingBottom: components.curtain.height * 1.5,
    '@media (max-width: 799px)': {
      display: 'none',
      margin: 0,
    },
  },
  mobileContainer: {
    paddingBottom: components.curtain.height * 1.5,
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },
}));
