export default ({ components, fonts }) => {
  const { height, background } = components.dropDown;

  return {
    button: {
      height: height.mobile,
      background,
      boxShadow: '0 2px 23px 0 rgba(204, 204, 204, 0.5)',
      width: '100%',
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      ...fonts.subtitleMedium,
      textDecoration: 'none',
    },
    cvsButton: {
      width: '100%',
      outline: 'none',
      background: 'none',
      border: 'none',
      '&:active': {
        background: '#bcbcbc',
      },
    },
  };
};
