import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Button = ({ type, name, url, target, onClick, classes }) => (
  <div className={classes.button}>
    {type === 'link' && (
      <a href={url} target={target} className={classes.link} rel="noopener noreferrer">
        {name}
      </a>
    )}
    {type === 'download' && (
      <a href={url} target={target} className={classes.link} rel="noopener noreferrer" download>
        {name}
      </a>
    )}
    {type === 'button' && (
      <button type="button" onClick={onClick} className={cn(classes.link, classes.cvsButton)}>
        {name}
      </button>
    )}
  </div>
);

Button.defaultProps = {
  type: 'link',
  url: '#',
  target: '_self',
  onClick: () => null,
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Button;
