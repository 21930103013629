import { createUseStyles } from 'react-jss';

export default createUseStyles(({ colors, spacing, fonts }) => {
  const { hover, click } = colors.link;

  return {
    container: {
      display: 'flex',
      marginBottom: spacing * 3,
    },
    imageContainer: {
      height: spacing * 40,
      minHeight: spacing * 40,
      width: spacing * 30,
      minWidth: spacing * 30,
      overflow: 'hidden',
    },
    image: {
      width: 'inherit !important',
      height: 'inherit !important',
      background: '#d8d8d8',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: [0, spacing * 6, 0, spacing],
    },
    nameText: fonts.subtitleBold,
    positionText: {
      ...fonts.subtitle,
      opacity: 0.6,
    },
    text: fonts.subtitleSmall,
    linkContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '&>div': {
        lineHeight: 1,
      },
    },
    insideContainer: {
      minWidth: spacing * 20,
      marginBottom: 10,
    },
    link: {
      ...fonts.subtitle,
      opacity: 0.6,
      marginLeft: spacing * 1.75,
      textDecoration: 'none',
      '&:hover': {
        opacity: 1,
        color: hover,
      },
      '&:active': {
        opacity: 1,
        color: click,
      },
    },
    button: {
      background: 'none',
      border: 'none',
      outline: 'none',
      padding: 0,
      cursor: 'pointer',
    },
  };
});
