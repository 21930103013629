import { createUseStyles } from 'react-jss';

export default createUseStyles(({ components, spacing, fonts }) => {
  const { height } = components.dropDown;

  return {
    imageContainer: (ratio = 1.334) => ({
      overflow: 'hidden',
      padding: [spacing * 3, spacing * 2, spacing, spacing * 2],
      width: `calc(100vw - ${4 * spacing}px)`,
      height: `calc((100vw - ${4 * spacing}px) / ${ratio})`,
    }),
    image: {
      width: 'inherit !important',
      height: 'inherit !important',
      background: '#d8d8d8',
    },
    nameText: { ...fonts.subtitleBold, padding: [0, spacing * 2] },
    positionText: {
      ...fonts.subtitleBold,
      opacity: 0.6,
      padding: spacing * 2,
      paddingTop: 0,
    },
    text: fonts.subtitleSmall,
    animateContainer: {
      overflow: 'hidden',
      height: 0,
      opacity: 0,
    },
    descriptionText: {
      padding: spacing * 2,
      paddingBottom: spacing * 4,
    },
    button: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      height: height.mobile,
      width: '100%',
      background: 'none',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      boxShadow: '0 2px 23px 0 rgba(204, 204, 204, 0.5)',
    },
    buttonTitle: {
      ...fonts.subtitleMedium,
      marginRight: spacing * 3,
    },
    iconWrapper: {
      position: 'absolute',
      right: spacing * 2,
      width: 25,
      height: 15,
    },
  };
});
