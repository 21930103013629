/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import CollapseIcon from '@/icons/CollapseIcon';
import DeployIcon from '@/icons/DeployIcon';

import { containerVariants } from './utils';
import useStyles from './styles';

const ItemMobile = ({ name, image, position, description, links }) => {
  const classes = useStyles(image && image.aspectRatio);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className={classes.imageContainer}>
        {image ? (
          <GatsbyImage image={image} objectFit="contain" objectPosition="50% 50%" className={classes.image} alt="" />
        ) : (
          <div className={classes.image} />
        )}
      </div>
      <p className={classes.nameText}>{name}</p>
      <p className={classes.positionText}>{position}</p>
      {(description.length > 0 || links.length > 0) && (
        <>
          <motion.div
            className={classes.animateContainer}
            variants={containerVariants}
            animate={open ? 'open' : 'close'}
            initial="close"
          >
            <div className={classes.descriptionText}>
              {description.map((text, index) => (
                <p key={index} className={classes.text}>
                  {text}
                </p>
              ))}
            </div>
            {links.map((props, index) => (
              <Button key={index} {...props} />
            ))}
          </motion.div>
          <button type="button" onClick={() => setOpen(!open)} className={classes.button}>
            <p className={classes.buttonTitle}>{open ? 'Weniger' : 'Mehr'}</p>
            <div className={classes.iconWrapper}>{open ? <CollapseIcon /> : <DeployIcon />}</div>
          </button>
        </>
      )}
    </div>
  );
};

ItemMobile.defaultProps = {
  links: [],
  description: [],
  image: undefined,
};

ItemMobile.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
  }),
};

export default ItemMobile;
